<template>
  <el-dialog
    title="农资集采"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="1500px"
  >
    <!-- 内层弹窗 -->
    <el-dialog
      width="50%"
      title="保证金凭证"
      :visible.sync="innerVisible"
      append-to-body
    >
      <div class="innerBox">
        <table border="1">
          <tr>
            <td>项目编号</td>
            <td>{{ innerList.procurementCode }}</td>
            <td>报名时间</td>
            <td>{{ innerList.applyTime }}</td>
          </tr>
          <tr>
            <td>保证金金额</td>
            <td>{{ innerList.prepayment }}</td>
            <td>缴费凭证上传时间</td>
            <td>{{ innerList.uploadTime }}</td>
          </tr>

          <tr>
            <td>报名人</td>
            <td>{{ innerList.applyName }}</td>
            <td>竞价人联系方式</td>
            <td>{{ innerList.contactPhone }}</td>
          </tr>
        </table>
        <img :src="innerList.bondFile" alt="" />
        <el-form
          :model="form"
          label-position="left"
          label-width="80px"
          inline
          ref="form"
        >
          <el-form-item label="备注">
            <el-input
              :disabled="true"
              style="width: 190px"
              v-model="form.bondNotice"
              placeholder=""
            >
            </el-input>
          </el-form-item>
          <el-form-item label="审核说明">
            <el-input
              style="width: 190px"
              v-model="form.rejectNotice"
              placeholder=""
            >
            </el-input>
          </el-form-item>

          <!--
          <div class="allBtn">
            <el-button
              type="primary"
              v-show="flag == 4 || flag == 6"
              :disabled="flag == 6"
              @click="derive(0)"
              >不通过</el-button
            >
            <el-button
              type="primary"
              v-show="flag == 4 || flag == 5"
              :disabled="flag == 5"
              @click="derive(1)"
              >通过</el-button
            >
          </div> -->
          <div class="allBtn">
            <el-button type="primary" v-show="flag == 4" @click="derive(0)"
              >不通过</el-button
            >
            <el-button type="primary" v-show="flag == 4" @click="derive(1)"
              >通过</el-button
            >
            <el-button type="info" v-show="flag == 5">审核通过</el-button>
            <el-button type="info" v-show="flag == 6">审核驳回</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>

    <div class="table">
      <div class="topTable">
        <div class="Statistics" v-if="MiningRegistration">
          <table border="1">
            <tr>
              <td>项目编号</td>
              <td>{{ MiningRegistration.procurementCode }}</td>
              <td>项目标题</td>
              <td>{{ MiningRegistration.title }}</td>
            </tr>
            <tr>
              <td>报名总人次</td>
              <td>{{ MiningRegistration.totalApply }}人</td>
              <td>已交保证金人次</td>
              <td>{{ MiningRegistration.totalPrepayment }}人</td>
            </tr>
          </table>
        </div>
        <div class="allBtn">
          <el-button type="primary" @click="ExportDetails()"
            >导出明细</el-button
          >
          <el-button type="primary" @click="close()">关闭</el-button>
        </div>
      </div>
    </div>
    <!-- 切换按钮 -->
    <div class="tabs">
      <div v-for="(item, index) in detailsList" :key="index">
        <span @click="detailBtn(index)">{{ item }}</span>
      </div>
    </div>
    <div class="ShowList">
      <div v-if="i == 0">
        <!-- 根据条件搜索    -->
        <div class="searchItem">
          <el-form
            :model="form"
            label-position="center"
            label-width="80px"
            inline
            ref="form"
          >
            <el-form-item label="报名人">
              <el-input
                style="width: 190px"
                v-model="form.applyName"
                placeholder="用户名"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="农资类型" prop="agriculturalType">
              <!--   :show-all-levels="false" -->
              <el-cascader
                ref="address"
                v-model="form.secondId"
                style="width: 200px"
                :options="TypeList"
                @change="handleChange"
                :show-all-levels="false"
                :props="props"
                clearable
              ></el-cascader>
            </el-form-item>
            <el-form-item label="生产商">
              <el-input
                style="width: 190px"
                v-model="form.producer"
                placeholder="用户名"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="form.status" style="width: 200px">
                <el-option
                  v-for="(item, index) in typesList"
                  :key="index"
                  :value="item.value"
                  :label="item.label"
                ></el-option
              ></el-select>
            </el-form-item>

            <div class="Btn">
              <el-button class="query-btn" type="primary" @click="search"
                >搜索</el-button
              >
              <el-button type="primary" @click="reset">清空</el-button>
            </div>
          </el-form>
        </div>

        <table border="1">
          <thead>
            <tr>
              <th>报名人</th>
              <th>联系方式</th>
              <th>农资类型</th>
              <th>详细规格</th>
              <th>农资生产商</th>
              <th>报名数量</th>
              <th>报名时间</th>
              <th>保证金</th>
              <th>状态</th>
              <th>操作</th>
            </tr>
          </thead>

          <tbody v-for="(item, index) in dataList" :key="index">
            <tr>
              <td :rowspan="dataList[index].applyAgriculturalVoList.length + 1">
                {{ item.applyName }}
              </td>
              <td :rowspan="dataList[index].applyAgriculturalVoList.length + 1">
                {{ item.contactPhone }}
              </td>
            </tr>

            <tr v-for="(its, ind) in item.applyAgriculturalVoList" :key="ind">
              <td>{{ its.agriculturalType }}</td>
              <td>{{ its.specifications }}</td>
              <td>{{ its.producer }}</td>
              <td>{{ its.quantity }}袋</td>
              <td
                v-if="ind == 0"
                :rowspan="dataList[index].applyAgriculturalVoList.length + 1"
              >
                {{ its.applyTime }}
              </td>
              <td
                v-if="ind == 0"
                :rowspan="dataList[index].applyAgriculturalVoList.length + 1"
              >
                {{ its.prepayment }}
              </td>
              <td
                v-if="ind == 0"
                :rowspan="dataList[index].applyAgriculturalVoList.length + 1"
              >
                {{
                  its.status == 0
                    ? "待支付"
                    : its.status == 1
                    ? "超时未支付"
                    : its.status == 2
                    ? "报名成功"
                    : its.status == 3
                    ? "已取消报名"
                    : its.status == 4
                    ? "保证金审核中"
                    : its.status == 5
                    ? "审核成功"
                    : its.status == 6
                    ? "审核失败"
                    : ""
                }}
              </td>
              <td
                v-if="ind == 0"
                :rowspan="dataList[index].applyAgriculturalVoList.length + 1"
              >
                <el-button
                  v-if="
                    its.status == 3 ||
                    its.status == 4 ||
                    its.status == 5 ||
                    its.status == 6
                  "
                  type="primary"
                  @click="innerBtn(its)"
                  >查看保证金凭证</el-button
                >
              </td>
            </tr>
          </tbody>
          <tbody v-if="!dataList.length">
            <tr>
              <td colspan="10">暂无数据</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="i == 1" class="type">
        <table border="1">
          <thead>
            <tr>
              <th>农资类型</th>
              <th>详细规格</th>
              <th>农资生产商</th>
              <th>单价</th>
              <th>报名总数</th>
              <th>保证金</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataList" :key="index">
              <td>{{ item.agriculturalType }}</td>
              <td>{{ item.specifications }}</td>
              <td>{{ item.producer }}</td>
              <td>{{ item.guidePrice + "元/" + item.unit }}</td>
              <td>{{ item.totalRegistrations +'*'+item.unit }}</td>
              <td>{{ item.premoney }}</td>
            </tr>
          </tbody>
          <tbody v-if="!dataList.length">
            <tr>
              <td colspan="6">暂无数据</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  selectDetailApplyForm,
  selectCounting,
  selectApplyForm,
  exportApplyReport,
  selectBondDetail,
  procurementAuditBond,
} from "@/api/CollectivePurchase";
import { mapGetters } from "vuex";
import { nodeList, treeList } from "@/api/CollectivePurchase";
export default {
  name: "NowDetails",

  data() {
    return {
      dialogVisible: true,
      detailsList: ["报名详情", "数量统计"],
      i: 0,
      form: {
        applyName: "",
        secondId: "",
        producer: "",
        status: "",
        rejectNotice: "",
        bondNotice: "",
      },
      TypeList: [],
      subsetList: [],
      oneList: [],
      typesList: [
        {
          label: "待支付",
          value: "0",
        },
        {
          label: "超时未支付",
          value: "1",
        },
        {
          label: "报名成功",
          value: "2",
        },
        {
          label: "已取消报名",
          value: "3",
        },
        {
          label: "保证金审核中",
          value: "4",
        },
        {
          label: "审核成功",
          value: "5",
        },
        {
          label: "审核失败",
          value: "6",
        },
      ],
      contentType: false,
      processData: false,
      query: {
        current: 1,
        size: 10,
      },
      dataList: [],
      procurementId: "",
      MiningRegistration: [], //集采报名数据，
      newAgriculturalVoList: [], //报名详情
      innerVisible: false,
      innerList: [],
      baseUrl: window.globalUrl.BASE_API,
      applyId: "",
      flag: 0,
      props: {
        value: "secondId",
        label: "label",
        children: "children",
      },
    };
  },
  computed: {
    ...mapGetters(["identityObj"]),
  },
  mounted() {
    //node树查询
    this.$nextTick(() => {
      this.nodeList();
      this.treeList();
      this.selectApplyForm();
    });
  },

  methods: {
    //关闭弹窗
    handleClose() {
      this.$emit("closeDialog", false);
    },
    async search() {
      let procurementId = this.procurementId;
      let secondId = this.form.secondId[1];
      this.form.secondId = secondId;
      console.log(this.form.secondId, " this.form.agriculturlType");
      let params = {
        ...this.form,
        ...this.query,
        procurementId,
      };
      console.log(params, "查看查询的params");
      let res = await selectApplyForm(params);
      this.dataList = res.data;
    },

    // 详情
    async detailShow(procurementId) {
      this.procurementId = procurementId;
      // let types = localStorage.getItem("cropTypeList");
      // this.types = JSON.parse(types);

      let params = {
        procurementId: procurementId,
      };
      let res = await selectDetailApplyForm(params);
      if (res.code == 0) {
        this.MiningRegistration = res.data;
      }
    },
    // 报名记录详情
    async selectApplyForm() {
      let procurementId = this.procurementId;
      let params = {
        ...this.query,
        procurementId,
      };
      let res = await selectApplyForm(params);
      this.dataList = res.data;
    },
    //数量统计
    async selectCounting() {
      let procurementId = this.procurementId;
      let params = {
        ...this.query,
        procurementId,
      };
      let res = await selectCounting(params);
      this.dataList = res.data.records;
    },
    detailBtn(index) {
      this.i = index;
      console.log(index, "查看index");
      switch (index) {
        case 0:
          // 报名记录详情
          this.selectApplyForm();
          break;
        case 1:
          //  历史记录
          this.selectCounting();
          break;
        default:
          break;
      }
    },

    //导出
    async ExportDetails() {
      let procurementId = this.procurementId;
      let params = {
        current: 1,
        size: 2,
        procurementId,
      };
      let res = await exportApplyReport(params);
      if (res) {
        let blob = res;
        const fileName = "导出报名明细.xlsx";
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
    //查看保证金凭证
    async innerBtn(item) {
      console.log(item, "查看item");
      this.applyId = item.applyId;
      let params = {
        applyId: item.applyId,
      };

      let res = await selectBondDetail(params);
      let newInnerList = res.data;
      // console.log(res.data, "查看res.data");
      this.$nextTick(() => {
        this.flag = res.data.status;
      });

      // //判断按钮是否禁用
      // if (res.data.status) {
      //   this.flag = 0;
      // } else if (res.data.status == 5) {
      //   this.flag = 1;
      // } else {
      //   this.flag = 3;
      // }
      newInnerList.bondFile =
        this.baseUrl +
        "admin/file/get?ossFilePath=" +
        JSON.parse(res.data.bondFile).url;

      this.innerList = newInnerList;
      this.innerVisible = true;
      this.form.bondNotice = newInnerList.bondNotice;
      this.form.rejectNotice = newInnerList.rejectNotice;
    },

    async derive(type) {
      if (type == 0) {
        this.flag = 6;
      } else if (1) {
        this.flag = 5;
      }

      let params = {
        auditStatus: type,
        applyerId: this.innerList.applyerId,
        rejectNotice: this.form.rejectNotice,
        applyId: this.applyId,
      };
      let res = await procurementAuditBond(params);
      if (res.code == 0) {
        this.$message.success(res.msg);
      }
      this.selectApplyForm();
    },

    // 农资规格管理-一级种类字典查询
    async nodeList() {
      let params = "first_crop_type";
      let res = await nodeList(params);
      this.oneList = res.data;
    },
    //农资规格管理-种类树状图查询
    async treeList() {
      this.childrenList = [];
      let params = {
        areaCode: this.identityObj.areaCode,
      };
      let res = await treeList(params);
      this.subsetList = res.data;
      this.allNode();
    },
    //农资类型下拉框选择
    handleChange(value) {
      this.typeIndex = value;
      console.log(this.typeIndex);
    },
    //组合成一个节点树的列表

    allNode() {
      let firstList = this.oneList;
      let secondList = this.subsetList;
      firstList.forEach((item) => {
        const newItem = {
          ...item,
          children: [],
        };
        secondList.forEach((items) => {
          if (item.id === items.parentId) {
            items.label = items.secondName;
            items.value = items.secondId;
            newItem.children.push(items);
          }
        });
        this.TypeList.push(newItem);
      });
      this.TypeList.forEach((item) => {
        item.secondId = item.value;
      });
    },
    async reset() {
      this.form.applyName = "";
      this.form.secondId = "";
      this.form.producer = "";
      this.form.status = "";
      this.form.rejectNotice = "";
      this.form.bondNotice = "";
      console.log(this.form, "查看form");
      let procurementId = this.procurementId;
      let params = {
        ...this.query,
        procurementId,
      };
      let res = await selectApplyForm(params);
      this.dataList = res.data;
    },
    //关闭按钮
    close() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  width: 100%;
  height: 100%;
  .topTable {
    width: 100%;
    display: flex;
    height: 60px;
    border-bottom: solid 1px #ccc;
    table {
      width: 100%;
      border-collapse: collapse;
      text-align: center;
    }
  }
  .Statistics {
    width: 78%;
  }
  .allBtn {
    margin-left: 20px;
  }
}
.tabs {
  display: flex;
  margin-top: 20px;
  span {
    margin-right: 20px;
    border: solid 2px #ccc;
    padding: 5px 5px;
    cursor: pointer;
    background: lavenderblush;
  }
}
.ShowList {
  width: 100%;
  /*根据条件搜索*/
  .searchItem {
    padding-top: 10px;
    .el-form {
      display: flex;
      flex-wrap: wrap;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  .type {
    margin-top: 65px;
  }
}

/deep/.innerBox {
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }
  img {
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }
  .el-form {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    .allBtn {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }
}
</style>

<template>
  <div class="AddCollection">
    <h3>农资新集采</h3>
    <!-- 报名详情 -->
    <ApplicationDetails
      v-if="detailsShow"
      @closeDialog="closeDialog"
      ref="detailsShow"
    />
    <!-- 根据条件搜索    -->
    <div class="searchItem">
      <el-form
        :model="form"
        label-position="center"
        label-width="80px"
        inline
        ref="form"
      >
        <el-form-item label="项目编号">
          <el-input
            style="width: 190px"
            v-model="form.procurementCode"
            placeholder="请输入项目编号"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="标题">
          <el-input
            style="width: 190px"
            v-model="form.title"
            placeholder="请输入标题"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="农资类别">
          <el-select v-model="form.secondId" style="width: 200px">
            <el-option
              v-for="(item, index) in childrenList"
              :key="index"
              :value="item.value"
              :label="item.label"
            ></el-option
          ></el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status" style="width: 200px">
            <el-option
              v-for="(item, index) in types"
              :key="index"
              :value="item.value"
              :label="item.label"
            ></el-option
          ></el-select>
        </el-form-item>

        <div class="Btn">
          <el-button class="query-btn" type="primary" @click="search"
            >搜索</el-button
          >
          <el-button type="primary" @click="add">发起新集采</el-button>
        </div>
      </el-form>
    </div>
    <table-page
      :data="tableData"
      :columns="columns"
      :currentPage="query.current"
      :pageSize="query.size"
      :total="total"
      :border="true"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
    >
      <template slot="paymentRatio" slot-scope="scope">
        <span>{{ scope.row.paymentRatio }}%</span>
      </template>

      <template slot="status" slot-scope="scope">
        <span>{{
          scope.row.status == 1
            ? "报名中"
            : scope.row.status == 2
            ? "报名截止"
            : scope.row.status == 3
            ? "已发布招标公告"
            : scope.row.status == 4
            ? "已发布中标公示"
            : ""
        }}</span>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button
          type="text"
          size="small"
          @click="detailsClick(scope.row.procurementId, scope.row.applyId)"
          >报名详情</el-button
        >
        <el-button
          type="text"
          size="small"
          v-if="scope.row.status == 2"
          @click="announcementClick(scope.row)"
        >
          发布招标公告
        </el-button>
        <el-button
          type="text"
          size="small"
          v-if="scope.row.status == 3"
          @click="TenderAnnouncement(scope.row)"
        >
          发布中标公示</el-button
        >
      </template>
    </table-page>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TablePage from "@/components/table/table-page";
import {
  selectUserApply,
  selectDetailApplyForm,
} from "@/api/CollectivePurchase";
import ApplicationDetails from "./components/details";
import { NewCollectivePurchase } from "../tableColumn";
import { nodeList, treeList } from "@/api/CollectivePurchase";
export default {
  name: "NowIndex",

  data() {
    return {
      form: {
        procurementCode: "",
        title: "",
        secondId: "",
        status: "",
      },
      keyForm: 1,
      columns: NewCollectivePurchase,
      query: {
        current: 1,
        size: 10,
      },
      types: [
        {
          label: "报名中",
          value: "1",
        },
        {
          label: "报名截止",
          value: "2",
        },
        {
          label: "已发布招标公告",
          value: "3",
        },
        {
          label: "已发布中标公告",
          value: "4",
        },
      ],
      tableData: [],
      total: 0,
      detailsShow: false,
      oneList: [],
      subsetList: [],
      childrenList: [],
    };
  },
  components: {
    TablePage,
    ApplicationDetails,
  },

  computed: {
    ...mapGetters(["identityObj"]),
  },

  mounted() {
    //查看列表
    this.selectUserApply();
    this.$nextTick(() => {
      //node树查询
      this.nodeList();
      this.treeList();
    });
  },

  methods: {
    //发起新集采
    add() {
      // this.$router.push({ name: "newPurchase", query: { c: data.xmbh } });
      this.$router.push({ name: "newPurchase" });
    },
    //中标集采公告
    announcementClick(obj) {
      this.$router.push({
        name: "CallForBids",
        query: { procurementId: obj.procurementId },
      });
    },

    //发布中标公示
    TenderAnnouncement(obj) {
      this.$router.push({
        name: "TenderAnnouncement",
        query: { tenderId: obj },
      });
      console.log("这是中标公示", obj);
    },

    handleCurrentChange(page) {
      this.query.current = page;
      this.selectUserApply();
    },
    //分页总页数切换
    handleSizeChange(size) {
      this.query = {
        current: 1,
        size: size,
      };
      this.selectUserApply();
    },
    //查看列表
    async selectUserApply() {
      let params = {
        size: this.query.size,
        current: this.query.current,
        areaCode: this.topSelectAreacode,
      };
      let res = await selectUserApply(params);
      this.tableData = res.data.records;
      console.log(this.tableData, "查看this.tableData");
      this.total = Number(res.data.total);
    },
    // 关闭弹框
    closeDialog() {
      this.detailsShow = false;
    },
    // 详情
    detailsClick(procurementId, applyId) {
      console.log(procurementId, applyId);
      this.detailsShow = true;
      // localStorage.setItem("cropTypeList", JSON.stringify(this.childrenList));
      // console.log(" this.childrenList", this.childrenList);
      this.$nextTick(() => {
        this.$refs.detailsShow.detailShow(procurementId);
      });
    },
    // 农资规格管理-一级种类字典查询
    async nodeList() {
      let params = "first_crop_type";
      let res = await nodeList(params);
      this.oneList = res.data;
    },
    //农资规格管理-种类树状图查询
    async treeList() {
      this.childrenList = [];
      let params = {
        areaCode: this.identityObj.areaCode,
      };
      let res = await treeList(params);
      this.subsetList = res.data;
      this.allNode();
    },
    //组合成一个节点树的列表
    allNode() {
      let firstList = this.oneList;
      let secondList = this.subsetList;
      this.$nextTick(() => {
        firstList.forEach((item) => {
          secondList.forEach((its) => {
            if (item.id === its.parentId) {
              let newName = item.label + "-" + its.secondName;
              this.childrenList.push({ label: newName, value: its.secondId });
            }
          });
        });
      });
    },
    // 搜索
    async search() {
      let params = {
        size: this.query.size,
        current: this.query.current,
        ...this.form,
      };
      let res = await selectUserApply(params);
      this.tableData = res.data.records;
      this.total = Number(res.data.total);
    },
  },
};
</script>

<style lang="less" scoped>
.AddCollection {
  /*根据条件搜索*/
  .searchItem {
    .el-form {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
